export const LOGIN = 'login';
export const SIGNUP = 'signup';
export const FORGOTTEN_PASSWORD = 'forgotten_password';
export const FORGOTTEN_PASSWORD_SUCCESS = 'forgotten_password_success';
export const MARKETING_OPT_IN = 'marketing_opt_in';
export const HELP = 'help';

export const ACTIONS = [
  LOGIN,
  SIGNUP,
  HELP,
  FORGOTTEN_PASSWORD,
  MARKETING_OPT_IN
];
export const ACTIONS_WITH_SOCIAL = [LOGIN, SIGNUP];

export default {
  LOGIN,
  SIGNUP,
  HELP,
  FORGOTTEN_PASSWORD,
  FORGOTTEN_PASSWORD_SUCCESS,
  MARKETING_OPT_IN,
  ACTIONS_WITH_SOCIAL,
  ACTIONS
};
