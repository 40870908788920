import PropTypes from 'prop-types';

import { getPagePath, trans } from '@spotahome/soyuz/client';

import { ACTIONS } from '../constants';

import styles from './LoginSignupModalTermsAndConditions.module.scss';

const LoginSignupModalTermsAndConditions = ({ action }) => (
  <div
    // eslint-disable-next-line react/no-danger
    className={styles['login-signup-modal-terms-and-conditions']}
    dangerouslySetInnerHTML={{
      __html: trans(`authentication.${action}.terms_and_conditions`, {
        terms: getPagePath('externals.terms-and-conditions'),
        privacy: getPagePath('externals.privacy-policies')
      })
    }}
  />
);

LoginSignupModalTermsAndConditions.propTypes = {
  action: PropTypes.oneOf(ACTIONS).isRequired
};

export default LoginSignupModalTermsAndConditions;
