const debounce = (fn, ms) => {
  let timeoutId;

  const cancel = () => {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }
  };

  const debounced = (...args) => {
    cancel();
    timeoutId = setTimeout(() => {
      fn(...args);
    }, ms);
  };

  debounced.cancel = cancel;

  return debounced;
};

export default debounce;
