import PropTypes from 'prop-types';

import styles from './FormError.module.scss';

const FormError = ({ error }) => {
  if (!error) {
    return null;
  }
  return (
    <div data-test="form-error" className={styles['form-error']}>
      <span>{error}</span>
    </div>
  );
};

FormError.propTypes = {
  error: PropTypes.string
};

FormError.defaultProps = {
  error: ''
};

export default FormError;
