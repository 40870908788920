import { useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { trans } from '@spotahome/soyuz/client';

import { SoyuzFlag } from '@spotahome/soyuz/client';

import FormProvider from '../../FormProvider';
import Button from '../../Button';

import LoginSignupModalTermsAndConditions from '../components/LoginSignupModalTermsAndConditions';
import LoginSignupModalToggle from '../components/LoginSignupModalToggle';

import { LOGIN, SIGNUP, HELP, FORGOTTEN_PASSWORD } from '../constants';

import styles from './LoginForm.module.scss';

const formSchema = yup.object().shape({
  email: yup
    .string()
    .ensure()
    .required('common.form.required-field')
    .email('lyp.validation.email.format'),
  password: yup.string().ensure().required('common.form.required-field')
});

const LoginForm = ({
  onToggleAction,
  onSubmit,
  error = null,
  disableLoginSignUpToggle = false,
  initialValues
}) => {
  const [isFormSubmitted, setFormSubmitted] = useState(null);

  const handleLoginSubmit = async ({ email, password }) => {
    try {
      await onSubmit({ email, password });
      setFormSubmitted(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleToggleAction = () => {
    onToggleAction(SIGNUP);
  };

  const handleHelpForm = () => {
    onToggleAction(HELP);
  };

  const handleForgotPasswordClick = () => {
    onToggleAction(FORGOTTEN_PASSWORD);
  };

  return (
    <FormProvider
      onSubmit={handleLoginSubmit}
      schema={formSchema}
      initialValues={initialValues}
      fieldsClassName={styles['login-form__field']}
    >
      <FormProvider.Input
        type="email"
        name="email"
        title={trans('loginsignup.modal.email')}
        dataTest="login-email"
        required
        autoFocus
        onBlurHandler={() => setFormSubmitted(false)}
      />

      <FormProvider.Password
        name="password"
        dataTest="login-password"
        title={trans('loginsignup.modal.password')}
        required
        onBlurHandler={() => setFormSubmitted(false)}
      />

      <div className={styles['login-form__bottom-buttons']}>
        <Button
          color="link"
          onClick={handleForgotPasswordClick}
          data-test="loginSignupModal_forgotPassword"
        >
          {trans('traditional.login-form.forgot-password')}
        </Button>
        <SoyuzFlag name="showHelpFormInLogin">
          {flag =>
            flag.isOn() && (
              <Button
                color="link"
                onClick={handleHelpForm}
                className={styles['login-form__help-form']}
                data-test="loginSignupModal_helpForm"
              >
                {trans('traditional.login-form.help-form')}
              </Button>
            )
          }
        </SoyuzFlag>
      </div>

      <div className={styles['login-form__footer']}>
        <span className={styles['login-form__submit']}>
          <FormProvider.Submit>
            {trans('loginsignup.modal.continue')}
          </FormProvider.Submit>
        </span>
        {isFormSubmitted && !!error && (
          <p
            data-test="login-form-error"
            className={styles['login-form__error']}
          >
            {error}
          </p>
        )}
        <span className={styles['login-form__terms-and-conditions']}>
          <LoginSignupModalTermsAndConditions action={LOGIN} />
        </span>
        {!disableLoginSignUpToggle && (
          <span className={styles['login-form__toggle']}>
            <LoginSignupModalToggle
              action={LOGIN}
              onToggleAction={handleToggleAction}
            />
          </span>
        )}
      </div>
    </FormProvider>
  );
};

LoginForm.propTypes = {
  onToggleAction: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.shape({
    handler: PropTypes.string,
    message: PropTypes.string
  }),
  disableLoginSignUpToggle: PropTypes.bool,
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string
  }).isRequired
};

export default LoginForm;
