import { trans } from '@spotahome/soyuz/client';

import styles from './LoginSignupModalSeparator.module.scss';

const LoginSignupModalSeparator = () => (
  <div className={styles['login-signup-modal-separator']}>
    {' '}
    {trans('login.account_or')}{' '}
  </div>
);

export default LoginSignupModalSeparator;
