import PropTypes from 'prop-types';

import { getResourcePath, trans } from '@spotahome/soyuz/client';

import { LOGIN } from '../constants';

import Button from '../../Button';

import './Success.scss';

const Success = ({ email, name, onToggleAction }) => {
  return (
    <div className="success-step">
      <p className="success-step__header">
        {trans('contact-us.success.title', { name })}
      </p>

      <div className="success-step__image">
        <img
          src={getResourcePath('/images/contact-us/contact-form-success.svg')}
          alt="success"
        />
      </div>

      <p className="success-step__priority">
        {trans('contact-us.success.priority.high')}
      </p>

      <p className="success-step__reach-email">
        {trans('contact-us.success.email', {
          email
        })}
      </p>

      <Button
        color="primary"
        size="large"
        fullWidth
        onClick={() => onToggleAction(LOGIN)}
        className="contact-us-form__button-success"
      >
        {trans('contact-us.success.button')}
      </Button>
    </div>
  );
};

Success.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onToggleAction: PropTypes.func.isRequired
};

export default Success;
