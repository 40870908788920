import { Fragment } from 'react';
import PropTypes from 'prop-types';

import styles from './LoginSignupModalTitle.module.scss';

const LoginSignupModalTitle = ({ renderTitle, renderSubtitle }) => (
  <Fragment>
    <p className={styles['login-signup-modal-title__title']}>{renderTitle()}</p>
    <p className={styles['login-signup-modal-title__subtitle']}>
      {renderSubtitle()}
    </p>
  </Fragment>
);

LoginSignupModalTitle.propTypes = {
  renderTitle: PropTypes.func.isRequired,
  renderSubtitle: PropTypes.func.isRequired
};

export default LoginSignupModalTitle;
