import PropTypes from 'prop-types';
import * as yup from 'yup';

import { trans } from '@spotahome/soyuz/client';

import FormProvider from '../../FormProvider';

import { LOGIN } from '../constants';

import styles from './ForgottenPasswordSuccessForm.module.scss';

const formSchema = yup.object().shape({});

const ForgottenPasswordSuccessForm = ({ onToggleAction }) => (
  <FormProvider
    dataTest="forgot-password-success"
    schema={formSchema}
    onSubmit={() => onToggleAction(LOGIN)}
    fieldsClassName={styles['forgotten-password-success-form']}
  >
    <FormProvider.Submit>
      {trans('loginsignup.modal.forgotten_password_success.back')}
    </FormProvider.Submit>
  </FormProvider>
);

ForgottenPasswordSuccessForm.propTypes = {
  onToggleAction: PropTypes.func.isRequired
};

export default ForgottenPasswordSuccessForm;
